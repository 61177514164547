import { AgentInformation, InvoiceAccount, Money, Port } from "@web/models";
import { WithRequiredProperty, isDefined } from "@web/utils";

import { OrderType } from "../models/OrderType";
import { ProductSku } from "../models/ProductSku";
import { Vessel } from "../models/Vessel";
import { Warehouse } from "../models/Warehouse";
import { ItemOriginalPrice } from "../models/order";

export enum RequisitionState {
  RECEIVED = "RECEIVED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING_CONFIRMATION = "PENDING_CONFIRMATION",
  CONFIRMED = "CONFIRMED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  CANCELLED = "CANCELLED",
}

interface RequisitionItem {
  impaCode?: string;
  sku: string;
  name: string;
  quantity: number;
  measurementUnit: string;
  totalPrice: Money;
  unitPrice: Money;
}

interface RequisitionInformation {
  comment?: string;
  requisitionState: RequisitionState;
  createdDate: Date;
}

export interface Requisition {
  id: string;
  name: string;
  estimatedDeliveryTime?: Date;
  status: RequisitionState;
  totalCost?: Money;
  requisitionItems: Array<RequisitionItem>;
  requisitionInformation: Array<RequisitionInformation>;
  grossTotal: Money;
  vesselName?: string;
  portName?: string;
}

export enum OrderStatus {
  // Common for both apps
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",

  // Only Lite app
  WAITING_FOR_SUPPLIER = "WAITING_FOR_SUPPLIER",
  REJECTED_BY_SUPPLIER = "REJECTED_BY_SUPPLIER",

  // Only Main app
  PENDING = "PENDING",
  PENDING_CONFIRMATION = "PENDING_CONFIRMATION",
}

export type OrderRequisitionStatus =
  | "CANCELLED"
  | "PENDING_CANCELLATION"
  | "SUPPLIER_ACKNOWLEDGED"
  | "SUPPLIER_CONFIRMED"
  | "SUPPLIER_PENDING"
  | "SUPPLIER_REJECTED"
  | "PENDING_PURCHASER_APPROVAL"
  | "PURCHASER_REJECTED"
  | "SUPPLIER_QUOTE_PENDING"
  | "SUPPLIER_QUOTE_ACKNOWLEDGED"
  | "SUPPLIER_QUOTE_PROVIDED"
  | "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED"
  | "SUPPLIER_DELIVERED"
  | "QUOTE_EXPIRED"
  | "QUOTE_REJECTED"
  | "DRAFT_CREATED"
  | "DRAFT_DELETED";

export interface RfqItem {
  id: string;
  name: string;
  quantity: number;
  measurementUnit: string;
  description?: string;
  exampleUrl?: string;
}

export interface OrderItem {
  name: string;
  quantity: number;
  entityQuantity: number;
  measurementUnit: string;
  totalAmount: Money;
  impaCode?: string;
  skuDetails: ProductSku;
  singleGrossPrice: Money;
  variantId: string;
  lineNumber?: number;
}

export interface OrderExtraItem {
  id: string;
  name: string;
  quantity: number;
  measurementUnit: string;
  refProductCode?: string;
  refUrl?: string;
}

export type ProductItem = {
  entityQuantity: number;
  measurementUnit: string;
  name: string;
  singleGrossPrice: Money;
  totalAmount: Money;
  impaCode?: string;
  skuDetails: ProductSku;
  lineNumber?: number;
  quantityInBasket?: number;
} & ItemOriginalPrice;

export type ChangeType = "ADDED" | "REMOVED" | "UPDATED" | "REPLACED";

export type ProductChange = {
  lineNumber: number;
  changeType: ChangeType;
  //
  id: string;
  quantity: number;
  name: string;
  measurementUnit: string;
  singleGrossPrice: Money;
  entityQuantity: number;
  supplierIdentifier: string;
  //
  oldId?: string;
  oldQuantity?: number;
  oldName?: string;
  oldMeasurementUnit?: string;
  oldSingleGrossPrice?: Money;
  oldEntityQuantity?: number;
  oldSupplierIdentifier?: string;
  // entities created on FE:
  totalAmount: Money;
  oldTotalAmount?: Money;
};

export type ReplacedItem = WithRequiredProperty<
  ProductChange,
  | "oldId"
  | "oldQuantity"
  | "oldName"
  | "oldMeasurementUnit"
  | "oldSingleGrossPrice"
  | "oldEntityQuantity"
  | "oldTotalAmount"
>;

export function isReplacedItemDataValid(item: ProductChange): item is ReplacedItem {
  const replacedItem = item as ReplacedItem;
  return (
    isDefined(replacedItem.oldId) &&
    isDefined(replacedItem.oldQuantity) &&
    isDefined(replacedItem.oldName) &&
    isDefined(replacedItem.oldMeasurementUnit) &&
    isDefined(replacedItem.oldSingleGrossPrice) &&
    isDefined(replacedItem.oldEntityQuantity) &&
    isDefined(replacedItem.oldTotalAmount)
  );
}

export type OrderRequisitionType = "ORDER" | "REQUISITION" | "QUOTATION" | "DRAFT";

export type Attachment = {
  attachmentId: string;
  category?:
    | "MD"
    | "SDOC"
    | "CERT"
    | "SDS"
    | "DWG"
    | "PHOTO"
    | "SOW"
    | "SVC_NOTES"
    | "OTHER"
    | "UNKNOWN";
  name: string;
  nameUploadedBy: string;
  size: number;
  uploadDate: string;
  uploadedBy: string;
};

export type PriceModifierInformation = {
  id: string;
  name: string;
  amount: Money;
  changeType: "DISCOUNT" | "ADDITIONAL";
};

export type SupplierInformation = {
  id: string;
  name: string;
};

export interface OrderRequisition {
  agentInformation?: AgentInformation;
  attachments?: Attachment[];
  canCancel?: boolean;
  canClose?: boolean;
  canDownloadMTML?: boolean;
  canDownloadExcel?: boolean;
  canDeleteDraft?: boolean;
  changeTypeList: Array<ChangeType>;
  changes?: Array<ProductChange>;
  comments: Array<Comments>;
  consolidated: boolean;
  createdAt: Date;
  quotationDate?: Date;
  requestDate?: Date;
  orderDate?: Date;
  customerOrderId?: string;
  deliveryDate: string;
  dutyFreeDeclaration?: DutyFreeDeclarationType;
  history: Array<OrderRequisitionHistory>;
  id: string;
  invoiceAccount?: InvoiceAccount;
  isRequisition?: boolean;
  isRfqRequisition: boolean;
  items: Array<OrderItem>;
  extraItems: Array<OrderExtraItem>;
  orderNotes?: string;
  port: Port;
  priceModifiers?: Array<PriceModifierInformation>;
  rejectionNotes?: string;
  requesterInformation: RequesterInformation;
  rfqItems?: Array<RfqItem>;
  status: OrderRequisitionStatus;
  storageLabel?: string;
  totalGrossAmount: Money;
  type: OrderRequisitionType;
  unchangedItems: Array<OrderItem>;
  updatedAt: Date;
  vessel: Vessel;
  warehouse?: Warehouse;
  supplierInformation: SupplierInformation;
  closed: boolean;
  orderType: OrderType;
  subject?: string;
}

export type OrderSummary = Pick<
  OrderRequisition,
  | "requesterInformation"
  | "deliveryDate"
  | "agentInformation"
  | "orderNotes"
  | "storageLabel"
  | "customerOrderId"
  | "dutyFreeDeclaration"
> & { consolidated: boolean; invoiceAccountId?: string; warehouseId?: string };

export type RequisitionSummary = {
  agentInformation?: AgentInformation;
  catalogItems?: Array<{ variantId: string; entityQuantity: number }>;
  comments: Array<Comments>;
  customerOrderId?: string;
  deliveryDate?: string;
  draftId?: string;
  invoiceAccountId?: string;
  consolidated: boolean;
  orderNotes?: string;
  portId: string;
  requesterInformation: RequesterInformation;
  rfqItems?: Array<Omit<RfqItem, "id">>;
  storageLabel?: string;
  supplierId: string;
  vesselId: string;
  warehouseId?: string;
};

export interface RequesterInformation {
  name: string;
  email: string;
}

export type OrderRequisitionHistory = {
  name: string;
  email: string;
  date: string;
  status: OrderRequisitionStatus;
  note?: string;
};

export type Comments = {
  name: string;
  date: string;
  type: "APPROVED" | "REJECTED" | "NOT_SUPPORTED" | "CANCELLATION_REQUESTED" | "CANCELLED";
  origin: "BUYER" | "SUPPLIER";
  text: string;
};

export type DutyFreeDeclarationType = {
  dutyFree: boolean;
  name?: string;
  position?: string;
};
