import { Combobox as HeadlessCombobox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/solid";
import { useState } from "react";

import { Label, Paragraph } from "@web/ui";

interface Props<T> {
  options: T[];
  filteredOptions: T[];
  messages: {
    placeholder: string;
    noValueLabel: string;
    noValueSubLabel: string;
    validationError?: string;
  };
  onSearchPatternChange: (pattern: string) => void;
  onSelectOption: (option: T | null) => void;
  placeholder?: string;
  preselectedOption?: T;
  optionComponent: (props: { value?: T }) => JSX.Element;
  hasError?: boolean;
}

export function Combobox<T extends { id: string }>({
  options,
  filteredOptions,
  messages,
  onSearchPatternChange,
  onSelectOption,
  preselectedOption: preselectedOptionProp,
  optionComponent: OptionComponent,
  hasError = false,
}: Props<T>) {
  const preselectedOption = options.find((value) => value.id === preselectedOptionProp?.id);
  const [selectedOption, setSelectedOption] = useState<T | undefined | null>(preselectedOption);

  const handleClearSelection = () => {
    onSelectOption(null);
    setSelectedOption(null);
  };

  const handleSelectOption = (option: T) => {
    onSelectOption(option);
    setSelectedOption(option);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchPatternChange(event.target.value);
  };

  if (options.length === 1) {
    return <OptionComponent value={options[0]} />;
  }

  return (
    <HeadlessCombobox as="div" value={selectedOption} onChange={handleSelectOption}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <div
              className={`flex items-center justify-between h-[110px] border border-solid ${
                hasError ? "border-dangerDefault" : "border-neutral_300"
              } pl-5 pr-7 py-6 bg-neutral_0`}
            >
              <div datatest-id="combobox-one-option">
                {selectedOption?.id ? (
                  <OptionComponent value={selectedOption} />
                ) : (
                  <>
                    <div
                      className={hasError ? "text-dangerDefault" : "text-textIcon-blackSecondary"}
                    >
                      {messages.noValueLabel}
                    </div>
                    <div>
                      <Label size="300" color={hasError ? "text-dangerDefault" : undefined}>
                        {messages.noValueSubLabel}
                      </Label>
                    </div>
                  </>
                )}
              </div>
              <div className="flex">
                {!open && selectedOption && (
                  <button onClick={handleClearSelection} title="clear">
                    <XIcon className="cursor-pointer h-4 w-4 text-text-whiteDisabled" />
                  </button>
                )}
                <HeadlessCombobox.Button
                  className="inset-y-0 right-0 rounded-r-md px-2 focus:outline-none h-5"
                  data-testid="combobox_button"
                >
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon
                      className="h-5 w-5 text-text-whiteDisabled"
                      aria-hidden="true"
                    />
                  )}
                </HeadlessCombobox.Button>
              </div>
            </div>
            {open && (
              <>
                <div className="flex justify-between w-full px-5 py-4 border border-neutral_300 bg-neutral_0 shadow-sm ">
                  <HeadlessCombobox.Input
                    className="w-full sm:text-sm focus:outline-none"
                    data-testid="combobox_input"
                    onFocus={() => onSearchPatternChange("")}
                    onChange={handleInputChange}
                    placeholder={messages.placeholder || ""}
                  />
                  <div className="flex items-center">
                    <SearchIcon
                      className="h-4 w-4 text-textIcon-blackSecondary"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                {filteredOptions.length > 0 && (
                  <HeadlessCombobox.Options
                    static
                    className="absolute z-10 max-h-[340px] w-full overflow-auto bg-neutral_0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    data-testid="combobox_options"
                  >
                    {filteredOptions.map((option) => (
                      <HeadlessCombobox.Option
                        key={option.id}
                        value={option}
                        className={({ active, selected }) =>
                          `flex flex-col justify-center cursor-pointer relative select-none py-2 pl-3 pr-9 border border-solid border-b-neutral_200 h-[110px]
                            ${active ? "bg-neutral_200" : ""} ${
                            selected
                              ? "bg-primaryBackground border solid sm:border-primaryDefault"
                              : ""
                          }`
                        }
                        data-testid="combobox_option"
                      >
                        {({ selected }) => (
                          <>
                            <OptionComponent value={option} />
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primaryDefault">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </HeadlessCombobox.Option>
                    ))}
                  </HeadlessCombobox.Options>
                )}
              </>
            )}
            {hasError && (
              <Paragraph
                size="200"
                color="text-dangerDefault"
                className="mt-2"
                data-testid="combobox_validationError"
              >
                {messages.validationError}
              </Paragraph>
            )}
          </div>
        </>
      )}
    </HeadlessCombobox>
  );
}
