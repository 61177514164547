import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common/network/model";

import { getOrderRequisitionsList } from "../api/getOrderRequisitionsList";
import { GetOrderRequisitionsListParams } from "../models/GetOrderRequisitionsListParams";

type OrderRequisitionsListResponse = {
  items: OrderRequisition[];
  cursor: string | undefined;
  requisitionsWaitingForApproval: number;
};

export const useOrderRequisitionsListQuery = ({
  status,
  vesselId,
  closed,
}: Omit<GetOrderRequisitionsListParams, "cursor">) =>
  useInfiniteQuery<
    OrderRequisitionsListResponse,
    DefaultError,
    InfiniteData<OrderRequisitionsListResponse>,
    QueryKey,
    string
  >({
    queryKey: ["orderRequisitionInfinite", status, vesselId, closed],
    queryFn: ({ pageParam }) =>
      getOrderRequisitionsList({
        cursor: pageParam,
        status,
        vesselId,
        closed,
      }),
    initialPageParam: "",
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
  });
