import { forwardRef } from "react";

import { OptionType, Select, SelectProps } from "../Select";

type Props = Omit<SelectProps, "options" | "value" | "onChange"> & {
  onChange: (value: string) => void;
  value?: string;
  initialValue?: string | null;
};

const TimePickerSelect = Select<string>;

const timePickerOptions: OptionType[] = Array.from(Array(24)).map((_, index) => {
  const hourString = `${index < 10 ? "0" : ""}${index}:00`;
  return {
    value: hourString,
    label: hourString,
  };
});

export const TimePicker = forwardRef<HTMLDivElement, Props>(
  ({ initialValue, value, onChange, ...rest }: Props, ref) => {
    return (
      <TimePickerSelect
        {...rest}
        ref={ref}
        options={timePickerOptions}
        value={timePickerOptions.find(
          (option) => option.value === (!value && !!initialValue ? initialValue : value)
        )}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        dropdownWidth="min-w-[130px]"
      />
    );
  }
);
TimePicker.displayName = "TimePicker";
