export const headingMap: Record<string, { group: string; tooltip?: boolean }> = {
  PENDING_PURCHASER_APPROVAL: {
    group: "layouts.base.requests",
  },
  PURCHASER_REJECTED: {
    group: "layouts.base.requests",
  },
  SUPPLIER_QUOTE_PENDING: {
    group: "layouts.base.quotations",
    tooltip: true,
  },
  SUPPLIER_QUOTE_PROVIDED: {
    group: "layouts.base.quotations",
    tooltip: true,
  },
  QUOTE_REJECTED: {
    group: "layouts.base.quotations",
    tooltip: true,
  },
  QUOTE_EXPIRED: {
    group: "layouts.base.quotations",
    tooltip: true,
  },
  SUPPLIER_PENDING: {
    group: "layouts.base.orders",
    tooltip: true,
  },
  SUPPLIER_ACKNOWLEDGED: {
    group: "layouts.base.orders",
    tooltip: true,
  },
  SUPPLIER_CONFIRMED: {
    group: "layouts.base.orders",
    tooltip: true,
  },
  SUPPLIER_DELIVERED: {
    group: "layouts.base.orders",
    tooltip: false,
  },
  PENDING_CANCELLATION: {
    group: "layouts.base.orders",
    tooltip: true,
  },
  CANCELLED: {
    group: "layouts.base.orders",
    tooltip: true,
  },
  SUPPLIER_REJECTED: {
    group: "layouts.base.orders",
    tooltip: true,
  },
};
