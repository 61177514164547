import { Attachment } from "@web/common/models/Attachment";

export const attachmentTypeToAttachmentNameMap: ReadonlyMap<Attachment["type"], string> = new Map([
  ["APPROVAL", "Approval"],
  ["DATA_SHEET", "Data Sheet"],
  ["DRAWING", "Drawing"],
  ["CERTIFICATE", "Certificate"],
  ["INSTRUCTIONS", "Instructions"],
  ["NOT_DEFINED", "Unspecified"],
]);
