import { PaperClipIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ProductSku } from "@web/common/models";
import { Label, Paragraph } from "@web/ui";
import { isDefined } from "@web/utils";

import { ProductItem } from "../network/model";
import { ItemTotal } from "./lists";

interface Props {
  productItems: Array<ProductItem>;
  lineThrough?: boolean;
  sideNote?: "Available" | "Not Available";
  className?: string;
  openProductDetailsModal?: (productSku: ProductSku) => void;
}

const tdStyle = "p-2 px-4 text-left relative";

export const ProductItemsTable = ({
  productItems,
  lineThrough = false,
  sideNote,
  className = "",
  openProductDetailsModal,
}: Props) => {
  const { t } = useTranslation();
  const hasItemWithLineItemNumber = productItems.find((item) => item.lineNumber) || "";
  const canDigitalAssetsColumnBeShown = productItems.find(
    (item) => item.skuDetails.attachments && item.skuDetails.attachments.length > 0
  );

  // TODO #2830: Fix not rounded tr on firefox
  return (
    <table
      className={classnames(className, "border-separate w-full pt-1", {
        "pl-5": hasItemWithLineItemNumber,
      })}
      style={{ borderSpacing: "0 0.25rem" }}
    >
      <tbody>
        {productItems.map((item: ProductItem, idx) => (
          <Fragment key={idx}>
            <tr
              className={classnames(
                "border bg-neutral_0 rounded shadow",
                isDefined(openProductDetailsModal) ? "cursor-pointer" : ""
              )}
              onClick={() => openProductDetailsModal?.(item.skuDetails)}
            >
              <td className={`${tdStyle} rounded-l`}>
                {!!item.lineNumber && (
                  <Label
                    size="200"
                    className="absolute right-full top-1/2 w-6 flex items-center justify-center"
                    style={{ transform: "translateY(-50%)" }}
                  >
                    {item.lineNumber}
                  </Label>
                )}
                <div className="flex flex-col">
                  <Label size="200">{item.name}</Label>
                  {sideNote ? (
                    <Paragraph
                      size="300"
                      color={
                        sideNote === "Available" ? "text-successDefault" : "text-dangerDefault"
                      }
                      className="pt-1"
                    >
                      {sideNote}
                    </Paragraph>
                  ) : (
                    !!item.impaCode && (
                      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
                        {t("pages.orderDetails.IMPA")} {item.impaCode}
                      </Paragraph>
                    )
                  )}
                </div>
              </td>
              <td
                className={classnames(tdStyle, {
                  "rounded-r": !canDigitalAssetsColumnBeShown,
                })}
              >
                <ItemTotal item={item} lineThrough={lineThrough} />
              </td>

              {canDigitalAssetsColumnBeShown && (
                <td className={`${tdStyle} rounded-r`}>
                  {item.skuDetails.attachments && item.skuDetails.attachments.length > 0 && (
                    <PaperClipIcon
                      className="w-5 text-textIcon-blackSecondary"
                      data-testid="ordersTable-itemColumn-attachment"
                    />
                  )}
                </td>
              )}
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
