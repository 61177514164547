import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, SearchIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { Port } from "@web/models";
import { AnchorWhite, CircledImage, Input, Paragraph } from "@web/ui";
import { useDebounce } from "@web/utils";

type Props = {
  configPorts?: Record<string, Port[]>;
  selectedPort?: Port;
  setPortHandler?: (port: Port) => void;
};

const DEBOUNCE_MS = 150;

export const PortSelector: React.FC<Props> = ({
  configPorts = {},
  selectedPort,
  setPortHandler,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const searchValue = useDebounce(inputValue, DEBOUNCE_MS);

  const searchPhrase = searchValue.toLowerCase();

  const displayCallback = (port: Port | undefined) => (
    <div className="flex items-center" key={port?.id}>
      <div className="pr-1">
        <CircledImage Icon={AnchorWhite} size={5} hashKey={port?.id} />
      </div>
      <Paragraph size="200" className="portSelectorLocodeText">
        {port?.locationCode}
      </Paragraph>
      <Paragraph size="200" className="portSelectorCountryText pl-1 pr-2">
        {port?.name}
      </Paragraph>
    </div>
  );

  return (
    <div>
      <Listbox value={selectedPort} onChange={setPortHandler}>
        {({ open }) => (
          <>
            <div className="relative h-full flex items-center content-center">
              <Listbox.Button
                data-testid="port-selector-button"
                className="py-2 text-left cursor-pointer focus:outline-none sm:text-sm flex justify-between border-b border-neutral_300"
              >
                <span className="block truncate pr-10 pl-2">{displayCallback(selectedPort)}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute max-h-screen right-4 top-7 z-10 mt-1 w-full bg-neutral_0 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  <Input
                    className="p-2"
                    prefixIcon={
                      <SearchIcon
                        className="h-4 w-4 text-textIcon-blackSecondary"
                        aria-hidden="true"
                      />
                    }
                    placeholder="Search port"
                    value={inputValue}
                    onChange={(v) => setInputValue(v.target.value)}
                  />
                  {Object.keys(configPorts).map((country, index) => (
                    <div key={country}>
                      <Paragraph
                        size="300"
                        color="text-textIcon-blackSecondary"
                        className="pl-4 py-2"
                      >
                        {t(`common.countries.${country}`)}
                      </Paragraph>
                      {(configPorts[country] || [])
                        .filter((v) => v.name.toLowerCase().includes(searchPhrase))
                        .map((port) => (
                          <Listbox.Option
                            as="div"
                            key={`${port.name}-${index}`}
                            className={({ active, selected }) =>
                              `${active || selected ? "text-neutral_0 bg-primaryDefault" : ""}
                           "cursor-default select-none relative py-2 pl-3 pr-9`
                            }
                            value={port}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    active || selected
                                      ? "text-neutral_0 bg-primaryDefault font-semibold"
                                      : "font-normal"
                                  }
                               block truncate`}
                                >
                                  {displayCallback(port)}
                                </span>

                                {selected ? (
                                  <span
                                    className={`
                                 ${active ? "text-neutral_0" : "text-primaryDefault"}
                                 absolute inset-y-0 right-0 flex items-center pr-4
                               `}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </div>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};
