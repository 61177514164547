/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListAppProduct } from "../models/ApiListAppProduct";
import type { AppProduct } from "../models/AppProduct";

export class ProductsService {
  /**
   * @deprecated
   * @returns AppProduct OK
   * @throws ApiError
   */
  public static getProductSku(
    {
      id,
      portId,
      vesselId,
    }: {
      id: string;
      portId: string;
      vesselId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/products/{id}",
      path: {
        id: id,
      },
      query: {
        portId: portId,
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListAppProduct OK
   * @throws ApiError
   */
  public static searchProducts(
    {
      portId,
      query,
      vesselId,
      sort,
      priceMin,
      priceMax,
      leadTime,
      brand,
      cursor,
    }: {
      portId: string;
      query: string;
      vesselId: string;
      sort?: "PRICE_ASC" | "PRICE_DESC";
      priceMin?: string;
      priceMax?: string;
      leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
      brand?: string;
      cursor?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/products/search",
      query: {
        portId: portId,
        query: query,
        sort: sort,
        priceMin: priceMin,
        priceMax: priceMax,
        leadTime: leadTime,
        brand: brand,
        cursor: cursor,
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @deprecated
   * @returns ApiListAppProduct OK
   * @throws ApiError
   */
  public static getProductsByCategoryId(
    {
      portId,
      categoryId,
      vesselId,
      sort,
      priceMin,
      priceMax,
      leadTime,
      brand,
      cursor,
    }: {
      portId: string;
      categoryId: string;
      vesselId: string;
      sort?: "PRICE_ASC" | "PRICE_DESC";
      priceMin?: string;
      priceMax?: string;
      leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
      brand?: string;
      cursor?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/products/category/{categoryId}",
      path: {
        categoryId: categoryId,
      },
      query: {
        portId: portId,
        sort: sort,
        priceMin: priceMin,
        priceMax: priceMax,
        leadTime: leadTime,
        brand: brand,
        cursor: cursor,
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListAppProduct OK
   * @throws ApiError
   */
  public static getProductsInCategory(
    {
      portId,
      categoryId,
      vesselId,
      sort,
      priceMin,
      priceMax,
      leadTime,
      brand,
      cursor,
    }: {
      portId: string;
      categoryId: string;
      vesselId: string;
      sort?: "PRICE_ASC" | "PRICE_DESC";
      priceMin?: string;
      priceMax?: string;
      leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
      brand?: string;
      cursor?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/products/category",
      query: {
        portId: portId,
        categoryId: categoryId,
        sort: sort,
        priceMin: priceMin,
        priceMax: priceMax,
        leadTime: leadTime,
        brand: brand,
        cursor: cursor,
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppProduct OK
   * @throws ApiError
   */
  public static getProduct(
    {
      id,
      portId,
      vesselId,
    }: {
      id: string;
      portId: string;
      vesselId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/products",
      query: {
        id: id,
        portId: portId,
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
