import { useTranslation } from "react-i18next";

import { InvoiceAccount } from "@web/models";
import { FormSection } from "@web/ui";
import { useFuzzySearch } from "@web/utils";

import { Combobox, InvoiceAccountUI } from "../../../components";

interface Props {
  invoiceAccounts: InvoiceAccount[];
  onSelectAccount: (account: { id: string } | null) => void;
  preselectedAccount?: InvoiceAccount;
  validationError?: string;
}

export const BillingInformation = ({
  invoiceAccounts,
  onSelectAccount,
  preselectedAccount,
  validationError,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { filteredCollection: filteredAccounts, handleSearchPatternChange } = useFuzzySearch({
    collection: invoiceAccounts,
    keys: ["registerOwner"],
  });

  const invoiceAccountsMessages = {
    placeholder: t("components.invoiceAccountSelector.placeholder"),
    noValueLabel: t("components.invoiceAccountSelector.noValueLabel"),
    noValueSubLabel: t("components.invoiceAccountSelector.noValueSubLabel"),
    validationError,
  };

  return (
    <FormSection
      subtitle={t("components.contactInfo.billingInformation.subtitle")}
      title={t("components.contactInfo.billingInformation.title")}
      testId="billingInformation"
    >
      <Combobox
        {...rest}
        filteredOptions={filteredAccounts}
        hasError={!!validationError}
        messages={invoiceAccountsMessages}
        onSearchPatternChange={handleSearchPatternChange}
        onSelectOption={onSelectAccount}
        optionComponent={InvoiceAccountUI}
        options={invoiceAccounts}
        preselectedOption={preselectedAccount}
      />
    </FormSection>
  );
};
