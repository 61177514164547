import { useFlag } from "@unleash/proxy-client-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IntersectionMonitor } from "@web/common";
import { LEGACY_OrdersTable } from "@web/common/components";
import { DeleteOrderDraftModal } from "@web/common/components/modals/DeleteOrderDraftModal";
import { OrderRequisition } from "@web/common/network/model";
import { Loading, Modal, Paragraph } from "@web/ui";
import { toBoolean } from "@web/utils";

import { useDeleteDraftMutation } from "src/hooks/useDeleteDraftMutation";

import routes from "../../config/routes";
import { useOrderRequisitionsListQuery } from "../../hooks/useOrderRequisitionsListQuery";
import BaseLayout from "../../layouts/Base";
import { GetOrderRequisitionsListParams } from "../../models/GetOrderRequisitionsListParams";
import { clearGatherBasket, useAppDispatch } from "../../store";
import { Heading } from "./components/Heading";

const getOrderRequisitionUrl = (order: OrderRequisition) => {
  return `${routes.orderDetails}/${order.id}`;
};

const Orders = () => {
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
  const [draftIdToDelete, setDraftIdToDelete] = useState<null | string>(null);
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const vesselId = searchParams.get("vesselId");
  const closed = toBoolean(searchParams.get("closed"));

  const {
    isPending,
    data: queryData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useOrderRequisitionsListQuery({
    status: status?.toUpperCase() as GetOrderRequisitionsListParams["status"],
    vesselId: vesselId || undefined,
    closed,
  });

  const dispatch = useAppDispatch();
  const { mutate: deleteDraft, isPending: isDeletingDraft } = useDeleteDraftMutation({
    onSuccess: () => {
      closeDeleteDraftModal();
      dispatch(clearGatherBasket());
      refetch();
    },
  });

  const hasRfQFeature = useFlag("rfq");

  const orderRequisitionsList = queryData
    ? queryData.pages
        .flatMap((p) => p.items)
        // TODO: remove when RfQ feature is finished
        .filter((order) => hasRfQFeature || !order.isRfqRequisition)
    : [];

  const requisitionsWaitingForApproval =
    queryData && queryData.pages ? queryData.pages[0].requisitionsWaitingForApproval : undefined;

  const closeDeleteDraftModal = () => setIsDeleteDraftModalOpen(false);

  const openDeleteDraftModal = (draftId: string) => {
    setIsDeleteDraftModalOpen(true);
    setDraftIdToDelete(draftId);
  };

  const handleDraftDeletion = (draftId: string) => {
    deleteDraft(draftId);
  };

  return (
    <>
      {draftIdToDelete && (
        <Modal isOpen={isDeleteDraftModalOpen} closeModal={closeDeleteDraftModal}>
          <DeleteOrderDraftModal
            closeModal={closeDeleteDraftModal}
            draftId={draftIdToDelete}
            isLoading={isDeletingDraft}
            onConfirm={handleDraftDeletion}
          />
        </Modal>
      )}
      <BaseLayout requisitionsWaitingForApproval={requisitionsWaitingForApproval}>
        <Heading status={status?.toUpperCase() || ""} closed={closed} />
        {isPending ? (
          <Loading />
        ) : !orderRequisitionsList || orderRequisitionsList.length === 0 ? (
          <div className="container w-full flex justify-center items-center flex-grow">
            <div>
              <Paragraph size="100">No results match your search criteria</Paragraph>
            </div>
          </div>
        ) : (
          <>
            <LEGACY_OrdersTable
              openDeleteDraftModal={openDeleteDraftModal}
              dataTestId="overviewTable"
              orders={orderRequisitionsList}
              getUrl={getOrderRequisitionUrl}
            />
            {hasNextPage && (
              <IntersectionMonitor onEnter={() => fetchNextPage()}>
                <div className="h-10 max-w-6xl">{isFetchingNextPage && <Loading />}</div>
              </IntersectionMonitor>
            )}
          </>
        )}
      </BaseLayout>
    </>
  );
};

export default Orders;
