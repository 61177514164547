import { Fragment, forwardRef, memo, useImperativeHandle, useRef } from "react";

import { findHighestCategoryBySelectedId } from "@web/common/utils";
import { Category } from "@web/models";

import { CategoriesTreeElement, CloseDisclosureType } from "./CategoriesTreeElement";

export type CloseAllDisclosureType = {
  closeAllDisclosures: () => void;
};

type Props = {
  categories: Category[];
  onCategoryClick: (category: Category) => void;
  openedByDefaultId?: string;
  activeCategoryId?: string;
};

/** @example
 * how to use closeAllDisclosures: store ref, pass ref to component and use ref method
 * const categoriesTreeRef = useRef<CloseAllDisclosureType>(null);
 * <CategoriesTree ref={categoriesTreeRef} />
 * categoriesTreeRef?.current?.closeAllDisclosures?.()
 */
export const CategoriesTree = memo(
  forwardRef<CloseAllDisclosureType, Props>(
    ({ categories, openedByDefaultId, onCategoryClick, activeCategoryId }, ref) => {
      const elementsRef = useRef<(CloseDisclosureType | null)[]>([]);

      useImperativeHandle(ref, () => ({
        closeAllDisclosures: () => {
          elementsRef.current.forEach((element) => element?.closeDisclosure?.());
        },
      }));

      const selectedCategory = findHighestCategoryBySelectedId(categories, activeCategoryId ?? "");

      return (
        <>
          {categories.map((categoryLevel1, index) => {
            const isSelectedCategory =
              selectedCategory?.id === categoryLevel1.id ||
              !!findHighestCategoryBySelectedId(categoryLevel1.children, activeCategoryId ?? "");

            return (
              <Fragment key={categoryLevel1.id}>
                <CategoriesTreeElement
                  ref={(closeDisclosure) => (elementsRef.current[index] = closeDisclosure)}
                  openedByDefaultId={openedByDefaultId}
                  onCategoryClick={onCategoryClick}
                  activeCategoryId={activeCategoryId}
                  categoryLevel1={categoryLevel1}
                  isSelectedCategory={isSelectedCategory}
                />
              </Fragment>
            );
          })}
        </>
      );
    }
  )
);
CategoriesTree.displayName = "CategoriesTree";
