import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Agent,
  DeliveryDateDetails,
  DeliveryMethod,
  DeliveryPort,
  Heading,
  Modal,
  PONumber,
  StorageLabel,
} from "@web/ui";
import { extractFromISODateString } from "@web/utils";

import { Vessel } from "../../models/Vessel";
import { OrderRequisition } from "../../network/model";
import ContactCard from "./../ContactCard";
import {
  ContactInfoModal,
  DutyFreeDeclarationInfo,
  History,
  OrderState,
  RequesterContact,
  WarehouseAddress,
} from "./components";

export * from "./components";

interface Props {
  order: Pick<
    OrderRequisition,
    | "agentInformation"
    | "customerOrderId"
    | "deliveryDate"
    | "dutyFreeDeclaration"
    | "history"
    | "port"
    | "requesterInformation"
    | "storageLabel"
    | "type"
    | "warehouse"
    | "closed"
  > & { vessel: Pick<Vessel, "name" | "imoNumber"> };
  shouldDisplayContactCard?: boolean;
  renderAttentionInfo?: () => React.ReactElement | null;
}

export const ActivityBox = ({
  order,
  shouldDisplayContactCard = false,
  renderAttentionInfo,
}: Props) => {
  const [isRequesterContactInfoModalOpen, setIsRequesterContactInfoModalOpen] = useState(false);
  const [isAgentContactInfoModalOpen, setIsAgentContactInfoModalOpen] = useState(false);

  const openRequesterContactInfoModal = () => setIsRequesterContactInfoModalOpen(true);
  const openAgentContactInfoModal = () => setIsAgentContactInfoModalOpen(true);

  const closeRequesterContactInfoModal = () => setIsRequesterContactInfoModalOpen(false);
  const closeAgentContactInfoModal = () => setIsAgentContactInfoModalOpen(false);
  const { t } = useTranslation();

  const onContactModalOpen = ({ type }: { type: "agent" | "requester" }) => {
    if (type === "requester") {
      openRequesterContactInfoModal();
    } else {
      openAgentContactInfoModal();
    }
  };

  return (
    <div className="flex flex-col px-5 rounded-lg border bg-neutral_0 py-5">
      <Modal isOpen={isRequesterContactInfoModalOpen} closeModal={closeRequesterContactInfoModal}>
        <ContactInfoModal
          closeModal={closeRequesterContactInfoModal}
          requesterInformation={order.requesterInformation}
          vesselName={order.vessel.name}
        />
      </Modal>
      <Modal isOpen={isAgentContactInfoModalOpen} closeModal={closeAgentContactInfoModal}>
        <ContactInfoModal
          closeModal={closeAgentContactInfoModal}
          agentInformation={order.agentInformation}
          vesselName={order.vessel.name}
        />
      </Modal>
      <div className="pb-4">
        <Heading size="300">Order Information</Heading>
      </div>
      <RequesterContact
        vesselName={order.vessel.name}
        requester={order.requesterInformation.name}
        onContactClick={() => onContactModalOpen({ type: "requester" })}
      />
      <hr />
      {renderAttentionInfo && renderAttentionInfo()}
      <div className="flex flex-col gap-4 py-3">
        <DeliveryPort portCode={order.port.locationCode} portName={order.port.name} />
        <DutyFreeDeclarationInfo dutyFreeDeclaration={order?.dutyFreeDeclaration} />
        {!!order.deliveryDate && (
          <DeliveryDateDetails
            date={extractFromISODateString(order.deliveryDate, "yearFirst")}
            hour={extractFromISODateString(order.deliveryDate, "justTime")}
            label={t("common.components.activityBox.desiredDelivery")}
          />
        )}
        {order.customerOrderId && <PONumber poNumber={order.customerOrderId} />}
        <Agent
          variant="base"
          agentInformation={order.agentInformation}
          onContactClick={() => onContactModalOpen({ type: "agent" })}
        />
        {order.history.map((hist) => hist.status).includes("PURCHASER_REJECTED") && (
          <History historyList={order.history} />
        )}

        {!!order.storageLabel && <StorageLabel label={order.storageLabel} />}
        {!!order.warehouse && (
          <DeliveryMethod
            deliveryMethod={<WarehouseAddress className="cursor-default" value={order.warehouse} />}
          />
        )}
        <OrderState isOrderClosed={order.closed} />
      </div>
      {order.type === "ORDER" && shouldDisplayContactCard && (
        <>
          <hr />
          <ContactCard />
        </>
      )}
    </div>
  );
};

export default ActivityBox;
