import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";

import { enGBShared } from "@web/common";

import enGB from "../locales/en-gb.json";

const setAppLanguage = (): Promise<TFunction> =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: {
            ...enGB,
            common: {
              ...enGBShared,
            },
          },
        },
      },
      lng: "en",
      fallbackLng: "en",
      returnNull: false,
      interpolation: {
        escapeValue: false,
      },
    });

export default setAppLanguage;
