import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const formatDate = (isoDate: string) => {
  try {
    return format(toZonedTime(parseISO(isoDate), "Z"), "dd MMM yyyy");
  } catch {
    return "";
  }
};

export const formatTime = (isoDate: string) => {
  try {
    return format(toZonedTime(parseISO(isoDate), "Z"), "EEE, HH:mm");
  } catch {
    return "";
  }
};
