import { DotsVerticalIcon } from "@heroicons/react/outline";
import { ArchiveIcon, DownloadIcon, XCircleIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";

import { OrderContextMenuActions } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network";
import {
  DividerDropdownItem,
  Dropdown,
  DropdownItem,
  IconButton,
  RegularDropdownItem,
} from "@web/ui";

interface Props {
  order: OrderRequisition;
  contextMenuActions?: OrderContextMenuActions;
}

// This component is only used in LEGACY_OrderLayout, which is used only in Main App
export const DotsButton: React.FC<Props> = ({ order, contextMenuActions }) => {
  const { canClose, canDownloadMTML, canDownloadExcel, canDeleteDraft, canCancel } = order;
  const isExcelDownloadAvailable = canDownloadExcel;
  const isOrderCancellationEnabled = useFlag("order-cancellation");
  const isOrderCancellationAvailable = canCancel && isOrderCancellationEnabled;

  const isAnyActionAvailable =
    isExcelDownloadAvailable ||
    canDownloadMTML ||
    canClose ||
    canDeleteDraft ||
    isOrderCancellationAvailable;

  // Only show divider if there are any `danger` actions available, and there is at least one `non-danger` action available
  const isDangerZoneDividerVisible =
    (isOrderCancellationAvailable || canDeleteDraft) &&
    (isExcelDownloadAvailable || canDownloadMTML || canClose);

  if (isAnyActionAvailable) {
    const items: DropdownItem[] = [
      ...(isExcelDownloadAvailable
        ? [
            {
              key: "downloadAsXlsx",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Download PO as XLSX"
                  variant="basic"
                  LeadingIcon={DownloadIcon}
                  onClick={
                    contextMenuActions?.onExcelDownload
                      ? contextMenuActions?.onExcelDownload
                      : () => null
                  }
                />
              ),
            },
          ]
        : []),
      ...(canDownloadMTML
        ? [
            {
              key: "downloadAsMtml",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Download PO as MTML"
                  variant="basic"
                  LeadingIcon={DownloadIcon}
                  onClick={
                    contextMenuActions?.onMTMLDownload
                      ? contextMenuActions?.onMTMLDownload
                      : () => null
                  }
                />
              ),
            },
          ]
        : []),
      ...(canClose
        ? [
            {
              key: "markAsClosed",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Close Order"
                  variant="basic"
                  LeadingIcon={ArchiveIcon}
                  onClick={
                    contextMenuActions?.openModalCloseOrder
                      ? contextMenuActions?.openModalCloseOrder
                      : () => null
                  }
                  data-testid="closeOrderButton"
                />
              ),
            },
          ]
        : []),
      ...(isDangerZoneDividerVisible
        ? [
            {
              key: "dangerZoneDivider",
              renderComponent: () => <DividerDropdownItem />,
            },
          ]
        : []),
      ...(isOrderCancellationAvailable
        ? [
            {
              key: "cancelOrder",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Cancel Order"
                  variant="danger"
                  LeadingIcon={XCircleIcon}
                  onClick={
                    contextMenuActions?.openModalCancelOrder
                      ? contextMenuActions?.openModalCancelOrder
                      : () => null
                  }
                />
              ),
            },
          ]
        : []),
      // TODO #9283: This case is only possible for `DSHeader:true` & `offlineDraft:false`
      // Remove this case when `offlineDraft` flag is removed
      ...(canDeleteDraft
        ? [
            {
              key: "deleteDraft",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Delete Draft"
                  variant="danger"
                  LeadingIcon={XCircleIcon}
                  onClick={
                    contextMenuActions?.onDeleteDraft
                      ? contextMenuActions?.onDeleteDraft
                      : () => null
                  }
                />
              ),
            },
          ]
        : []),
    ];

    return (
      <Dropdown hAlignTo="end" vAlignTo="bottom" items={items} data-testid="contextMenu">
        <IconButton
          size="large"
          variant="secondary"
          shape="square"
          label="More actions"
          Icon={DotsVerticalIcon}
          data-testid="contextMenu_button"
          isControlled
        />
      </Dropdown>
    );
  }

  return null;
};
